import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout/index"
import cn from "classnames"
import Data from "../data/data"
import LOGO from "../images/logo-white.svg"
import { graphql } from 'gatsby';

import style from "./login.module.css"

export const query = graphql`
  query EnvQuery3 {
    site {
      siteMetadata {
        apiServer
      }
    }
  }
`;

class ForgotPage extends React.Component {
  state = {
    email: "",
    resetSuccess: false,
  }

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value.trim(),
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { apiServer } = this.props.data.site.siteMetadata;
    const { email } = this.state;
    this.setState({ resetSuccess: false });

    if (email) {
      fetch(`${apiServer}/api/users/resetPassword`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      this.setState({ resetSuccess: true });
    }
  }

  render() {
    const { email } = this.state
    return (
      <>
        <Layout>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{Data.forgot.title}</title>
            <meta
              name="description"
              content={Data.metaData.description.forgot}
            />
            <link
              href="https://fonts.googleapis.com/css?family=Raleway:700&display=swap"
              rel="stylesheet"
            />
          </Helmet>

          {/* Banner */}
          <div className={style.root}>
            <div className={style.login__banner}>
              <div className="container">
                <div className={`row ${style.customRow}`}>
                  <div className="col s12">
                    <div className={style.login__bannerContent}>
                      <h1 className={style.login__heading}>
                        <img className={cn(style.logo)} src={LOGO} alt="Logo" />
                      </h1>
                      <form
                        className={style.loginForm}
                        onSubmit={this.handleSubmit}
                      >
                        <div className={style.form__resetInstructions}>
                          Please enter the email address that you normally use to login.
                        </div>
                        <input
                          type="text"
                          name="email"
                          value={email}
                          onChange={this.handleInputChange}
                          placeholder="Email"
                          className={cn(style.formInput)}
                        />
                        <div className={cn(style.form__loginError, { [style.active]: this.state.resetSuccess })}>
                          Password reset instructions have been sent to you by email.
                        </div>
                        <div className={style.form__buttons}>
                          <button type="submit" className={style.formSubmit}>
                            Reset Password
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

ForgotPage.propTypes = {}

export default ForgotPage
